import React from 'react';

import Header from './header';
import Footer from './footer';
import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = theme =>
  createStyles({
    layoutWrapper: {
      margin: '0 auto',
      padding: '0 1.2rem',
      paddingTop: 70,
      maxWidth: 1000
    }
  });

const Layout = ({ children, classes }) => {
  return (
    <div onTouchStart={() => ''}>
      <Header />
      <div className={classes.layoutWrapper}>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default withStyles(styles)(Layout);
