import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { createStyles, withStyles } from '@material-ui/core/styles';

import Styles from './footer.module.scss';

const styles = theme =>
  createStyles({
    footer: {
      textAlign: 'center',
      backgroundColor: 'white',
      marginTop: '2.0rem',
      height: 80
    },
    footerWrapper: {
      height: 'inherit'
    },
    link: {
      margin: '0 5%'
    }
  });

const Footer = ({ classes }) => (
  <div className={Styles.footerWrapper}>
    <div className={Styles.footer}>
      <div className={Styles.footerLinksWrapper}>
        <div className={Styles.footerLinks}>
          <OutboundLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.wantedly.com/companies/giftee"
          >
            採用情報
          </OutboundLink>
          <OutboundLink target="_blank" rel="noopener noreferrer" href="https://giftee.com/">
            giftee.com
          </OutboundLink>
          <OutboundLink target="_blank" rel="noopener noreferrer" href="https://giftee.co.jp/">
            運営会社
          </OutboundLink>
        </div>
      </div>
    </div>
  </div>
);

export default withStyles(styles)(Footer);
